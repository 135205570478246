<template>
  <div>
    <v-row align="start" justify="center">
      <v-menu
        v-model="menu"
        offset-y
        max-height="300px"
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            style="cursor: text;"
            dense
            v-model="search"
            v-bind="attrs"
            v-on="on"
            label="Buscar Lead (Nombre, Correo, Teléfono o Llegó por)"
            :hint="
              searched
                ? 'Presiona ' +
                  `${enterString}` +
                  'para filtrar todas las coincidencias'
                : ''
            "
            prepend-inner-icon="mdi-magnify"
            @keyup.enter="searchedAllCoincidences"
            @input="searchLead"
          >
          </v-text-field>
        </template>
        <v-list v-if="searched">
          <div v-if="notFound && !leadsSearchedLoadingSt && !searching">
            <v-list-item-title class="mx-2 font-size-searched-searching ">
              No se han encontrado resultados..
            </v-list-item-title>
            <v-img
              contain
              lazy-src="https://res.cloudinary.com/capital28-investments/image/upload/v1640032741/Capital%2028/Common/no_result_lead.png"
              max-height="100"
              max-width="150"
              src="https://res.cloudinary.com/capital28-investments/image/upload/v1640032741/Capital%2028/Common/no_result_lead.png"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <div v-else>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              @click="setActualLead(item._id)"
            >
              <v-list-item-title class="font-size-searched-name ">
                <text-highlight :queries="queries">
                  {{ item.contact_lead_name }}
                </text-highlight>
              </v-list-item-title>
              <v-list-item-title class="font-size-searched-email ">
                <div v-if="item.contact_lead && item.contact_lead.email">
                  <text-highlight :queries="queries">
                    {{ item.contact_lead.email }}
                  </text-highlight>
                </div>
                <div v-else>
                  -
                </div>
              </v-list-item-title>
              <v-list-item-title class="font-size-searched-phone ">
                <div v-if="item.contact_lead && item.contact_lead.phone">
                  <text-highlight :queries="queries">
                    {{ item.contact_lead.phone }}
                  </text-highlight>
                </div>
                <div v-else>
                  -
                </div>
              </v-list-item-title>
              <v-list-item-title class="font-size-searched-zones ">
                <div v-if="item.zones">
                  <text-highlight :queries="queries">
                    {{ item.zones }}
                  </text-highlight>
                </div>
                <div v-else>
                  -
                </div>
              </v-list-item-title>

              <v-list-item-title class="font-size-searched-zones ">
                <span v-if="item.phase === 'discarded'">
                  <v-chip
                    style="font-weight: 500;  background-color: #eee;"
                    class="v-chipActive "
                  >
                    Descartado
                  </v-chip>
                </span>
                <span v-if="item.phase === 'finished'">
                  <v-chip
                    style="font-weight: 500; font-size: 14px;  background-color: #fff8e1;"
                    class="v-chipActive"
                  >
                    Venta
                  </v-chip>
                </span>
                <!-- Se agrega datos como Broker externo (Analizar si se tiene que agregar el icono..) -->
                <span v-if="item.contact_external_broker_id">
                  <v-chip
                    style="font-weight: 500; font-size: 14px;  background-color: #fff8e1;"
                    class="v-chipActive"
                  >
                    Broker Externo
                  </v-chip>
                </span>
                <!-- Si es una multioportunidad -->
                <span v-if="isMultiOportunity(item)">
                  <v-chip
                    style="font-weight: 500; font-size: 14px;  background-color: #fff8e1;"
                    class="v-chipActive"
                  >
                    Multi-Oportunidad
                  </v-chip>
                </span>
              </v-list-item-title>
            </v-list-item>
          </div>

          <v-list-item v-if="searching || leadsSearchedLoadingSt">
            <v-list-item-title class="font-size-searched-searching "
              >Buscando ...
            </v-list-item-title>
            <v-progress-circular size="25" indeterminate></v-progress-circular>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item-title class="mx-2 font-size-searched-searching ">
            Escribe algo para comenzar a buscar ...
          </v-list-item-title>
        </v-list>
      </v-menu>

      <v-btn
        v-if="filterLeadSearched"
        icon
        rounded
        elevation="2"
        class="mx-2 mt-2 black"
        @click="resetLeads()"
      >
        <v-icon color="white">
          mdi-close
        </v-icon>
      </v-btn>
    </v-row>
    <lead-detail-modal
      :page="'table'"
      ref="leadDetailModal"
      :dialog="isDialogActive"
      @setDialog="setDialog"
    />
  </div>
</template>

<script>
import Vue from "vue";
import TextHighlight from "vue-text-highlight";

Vue.component("text-highlight", TextHighlight);
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: {
    showContactKpis: Boolean,
    sources: Array
  },
  components: {
    LeadDetailModal: () => import("@/components/lead/LeadDetailModal.vue")
  },
  computed: {
    ...mapState({
      leadsTableFooterSt: state => state.leads.leadsTableFooter,
      leadsSearchedLoadingSt: state => state.leads.leadsSearchedLoading,
      leadsSearchedSt: state => state.leads.leadsSearched
    }),
    filterLeadSearched: {
      get() {
        return this.$store.state.leads.filterLeadsSearched;
      },
      set(newValue) {
        this.$store.commit("leads/setFilteredLeadsSearched", newValue);
      }
    }
  },
  data() {
    return {
      notFound: false,
      queries: "@",
      description: "Tropical birds scattered as Drake veered the Jeep",
      enterString: '"Enter"',
      searched: false,
      isDialogActive: false,
      searching: false,
      timer: null,
      waitTime: 800,
      search: "",
      menu: false,
      items: []
    };
  },
  methods: {
    ...mapActions({
      fetchActualLead: "leads/fetchActualLead",
      fetchLeadsleadsSearchedSt: "leads/fetchLeadsSearched"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading",
      setLeadsTableFooterSt: "leads/setLeadsTableFooter",
      setLeadsPaginationResetSt: "leads/setLeadsPaginationReset",
      setFilteredLeadsSearchedSt: "leads/setFilteredLeadsSearched",
      updateActualLead: "leads/updateActualLead",
      setSelectedItems: "leads/setSelectedItems",
      setFilterShowDiscardedAndActiveSt: "setFilterShowDiscardedAndActive",
      setSecondaryLead: "leads/setSecondaryLead"
    }),
    isMultiOportunity(lead) {
      if (lead.hasOwnProperty("multi_sales_opportunities"))
        return lead.multi_sales_opportunities.length > 0;
      return false;
    },
    hiddenContactKpis() {
      this.$emit("getShowContactKpis", !this.showContactKpis);
    },
    setActualLead(leadId) {
      let self = this;
      self.setLoading(true);
      self.setDialog(true);
      // resetear leads seleccionados (checkboxes)
      self.selectedItems = [];
      //this.$refs.leadDetailModal.getTrafficData();
      this.fetchActualLead(leadId)
        .then(lead => {
          let data = JSON.parse(JSON.stringify(lead));
          this.$store.commit("leads/setSecondaryLead", data);
        })
        .finally(() => {
          setTimeout(() => {
            self.setLoading(false);
          }, 1000);
        });
    },
    resetLeads() {
      this.search = "";
      this.filterLeadSearched = false;
      this.setLeadsPaginationResetSt();
      this.setLeadsTableFooterSt({
        total: this.leadsTableFooterSt.total,
        page: 0,
        pages: this.leadsTableFooterSt.total / 25
      });
    },
    searchedAllCoincidences() {
      this.menu = false;
      this.setSelectedItems([]);
      this.setFilterShowDiscardedAndActiveSt(false);
      this.filterLeadSearched = this.items.length > 0;
      this.setLeadsTableFooterSt({
        total: this.leadsTableFooterSt.total,
        page: 0,
        pages: 1
      });
    },
    setDialog(isActive) {
      this.isDialogActive = isActive;
    },
    getLeadsSearched() {
      this.notFound = false;
      if (this.search) {
        this.fetchLeadsleadsSearchedSt(this.search).then(res => {
          this.queries = this.search;
          let final = res;
          if (final.length > 10) {
            for (let i = 0; i < 10; i++) {
              this.items.push(final[i]);
            }
          } else {
            this.items = final;
            if (this.items.length === 0) {
              this.notFound = true;
            }
          }
        });
      }
    },
    searchLead() {
      const self = this;
      self.searched = this.search ? true : false;
      self.filterLeadSearched = false;
      self.items = [];
      self.menu = this.search ? true : false;
      self.searching = true;
      clearTimeout(self.timer);
      self.timer = setTimeout(() => {
        self.getLeadsSearched();
        self.searching = false;
      }, self.waitTime);
    }
  }
};
</script>

<style>
.text__highlight {
  background: #b0d12a !important;
  border-radius: 0px !important;
}
</style>
<style scoped>
.v-text-field >>> label {
  font-size: 14px !important;
}
.font-size-text-field {
  font-size: 8px !important;
}
.font-size-searched-name {
  font-size: 14px !important;
}
.font-size-searched-email {
  font-size: 14px !important;
}
.font-size-searched-phone {
  font-size: 11px !important;
}
.font-size-searched-zones {
  font-size: 14px !important;
}
.font-size-searched-searching {
  font-size: 14px !important;
}

.v-menu__content.menuable__content__active {
  background-color: white;
  padding: 14px 0;
}
</style>
